<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeDialog"
        transition="dialog-top-transition"
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar
              class="grey lighten-3"
              elevation="0"
              dense
          >

            <v-toolbar-title>Read File Excel</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                color="red"
                icon
                @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="justify-center">

            <v-row justify="center" align="center">
              <v-col cols="12" sm="12" md="6">
                <v-file-input
                    v-model="currentFile"
                    show-size
                    counter
                    label="Pilih File Excel"
                    accept=".xls,.xlsx"
                    @change="selectFile"
                ></v-file-input>
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                    v-model="fdivisionBeanSelected"
                    :items="itemsFDivision"
                    item-value="id"
                    item-text="description"
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Hanya untuk Divisi (Div)"
                    hint="Tidak Boleh Kosong"
                    persistent-hint
                    :rules="rulesNotEmtpy"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="2" class="text-center">
                <v-btn color="success" small @click="startUpload" :disabled="!valid || !currentFile  || sudahUpload===true">
                  Upload
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-col>


            </v-row>

            <v-row v-if="currentFile">
              <v-col cols="12" sm="12" md="12">

                <v-data-table
                    :headers="headers"
                    :items="itemsDataUploaded"
                    item-key="name"
                    class="elevation-1"
                    height="400"
                    calculate-widths
                    hide-default-footer
                    disable-pagination
                >
                  <template v-slot:[`item.number`]="{ item }" >
                    <span><v-icon small color="green" v-if="item.statusUpload===1">mdi-check-circle</v-icon></span>
                    <span><v-icon small color="red" v-if="item.statusUpload===2">mdi-close</v-icon></span>
                    {{ item.number }}
                  </template>

                </v-data-table>
              </v-col>

            </v-row>

            <div v-if="progress">
              <div>
                <v-progress-linear
                    v-model="progress"
                    color="light-blue"
                    height="25"
                    reactive
                >
                  <strong>{{ progress }} %</strong>
                </v-progress-linear>
              </div>
            </div>

            <v-row v-if="previewImage" justify="center">
              <v-img class="ma-2" :src="previewImage" alt="" max-width="300px" max-height="300px"></v-img>
            </v-row>

          </v-card-text>
        </v-form>
      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>


    </v-dialog>

    <v-dialog
        v-model="dialogProgres"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please Wait...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import FCustomer from "../../../models/f-customer";
import FMaterialService from "@/services/apiservices/f-material-service";

export default {
  name: "FMaterialMappingDialog",
  props:{
    parentId: Number,
    itemsFSectorType: [],
    itemsFSatuan: [],
    itemsFDivision: []
  },
  components:{
  },
  data() {
    return {
      valid: false,
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: '',
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      progress: 0,
      message: "",
      description: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Kode (pcode)', value: 'kode1' },
        { text: 'Nama Material (pname)', value: 'description' },
        { text: 'Principal Code', value: 'mappingInCode1' },
        { text: 'InCode-2', value: 'mappingInCode2' },
        { text: 'InCode-3', value: 'mappingInCode3' },
        // { text: 'fdivisionBean', value: 'fdivisionBean' }
      ],
      itemsDataUploaded:[],
    };
  },
  computed: {
  },

  methods: {

    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe =0
      readXlsxFile(this.currentFile).then( (rows) =>{
        rows.forEach( item => {
          if (rowKe >0){

            let fFCustomerBean = new FCustomer()
            fFCustomerBean.number = rowKe+1


            fFCustomerBean.statusUpload =0
            fFCustomerBean.kode1 = item[0]!==null?item[0].toString():''
            fFCustomerBean.description = item[1]!==null?item[1].toString():''
            fFCustomerBean.mappingInCode1 = item[2]!==null?item[2].toString():''
            fFCustomerBean.mappingInCode2 = item[3]!==null?item[3].toString():''
            fFCustomerBean.mappingInCode3 = item[4]!==null?item[4].toString():''

            this.itemsDataUploaded.push(fFCustomerBean)

          }

          rowKe++
        })
      })

    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      this.dialogProgres = true

      let items = []
      this.itemsDataUploaded.forEach( item => {
        item.fdivisionBean = this.fdivisionBeanSelected
        items.push(item)
      })

      FMaterialService.updateMappingFMaterialList(items).then(
          response =>{

            // console.log("====== oke bos ========")
            this.sudahUpload = true
            this.dialogProgres = false
            // this.dialogShow = false
            // console.log(JSON.stringify(this.itemsDataUploaded))

            // this.itemsDataUploaded = response.data
            response.toString()

          },
          error =>{
            console.log(error.data)
          }
      )
      

    },

    showDialog(){
      this.dialogShow = true
      this.currentFile = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

      this.sudahUpload = false

      this.itemsDataUploaded = []

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }
      if (this.sudahUpload) {
        this.$emit('importExcelCompleted', "OKE")
      }

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },


  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
