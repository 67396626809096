<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>

            <v-container>
              <v-tabs>
                <v-tab>Deskripsi</v-tab>
                <v-tab :disabled="visibleSecurity">UOM-Price-COGS</v-tab>
                <v-tab :disabled="visibleSecurity">Inventory</v-tab>
                <v-tab :disabled="visibleSecurity">Accounting</v-tab>
                <v-tab :disabled="visibleSecurity">OL-Store-Desc</v-tab>
                <v-tab :disabled="visibleSecurity">OL-Store-Gallery</v-tab>
                <v-tab-item>
                  <FMaterialGeneral
                      :visibleSecurity="visibleSecurity"
                      :itemModified="itemModified"
                      :itemsFDivision="itemsFDivision"

                      :itemsMaterialType="itemsMaterialType"
                      :itemsFMaterialGroup3="itemsFMaterialGroup3"
                      :itemsFMaterialSalesBrand="itemsFMaterialSalesBrand"
                      :itemsFVendor="itemsFVendor"
                      :itemsFWarehouse="itemsFWarehouse"
                  ></FMaterialGeneral>
                </v-tab-item>
                <v-tab-item>
                  <FMaterialUomAndPrice
                      :itemModified="itemModified"
                      :itemsFDivision="itemsFDivision"
                      :itemsFTax="itemsFTax"
                  >
                  </FMaterialUomAndPrice>
                </v-tab-item>
                <v-tab-item>
                  <FMaterialInventory
                      :itemModified="itemModified"
                      :itemsFDivision="itemsFDivision"
                  >
                  </FMaterialInventory>
                </v-tab-item>
                <v-tab-item>
                  <FMaterialAccounting
                      :itemModified="itemModified"
                      :itemsFDivision="itemsFDivision"
                  >
                  </FMaterialAccounting>
                </v-tab-item>

                <v-tab-item>
                  <FMaterialOlStoreDesc
                      :itemModified="itemModified"
                      :parendId="itemModified.id"
                  >
                  </FMaterialOlStoreDesc>
                </v-tab-item>

                <v-tab-item>
                  <FMaterialOlStoreGallery
                      :itemModified="itemModified"
                      :parendId="itemModified.id"
                      :itemsFile="itemsFile"
                      @retrieveFiles="retrieveFiles"
                  >
                  </FMaterialOlStoreGallery>
                </v-tab-item>

              </v-tabs>

            </v-container>

          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down mr-10"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>

  </div>

</template>

<script>
import FMaterialService from '../../../services/apiservices/f-material-service';
import FMaterialFileService from "../../../services/apiservices/f-material-file-service"

import CloseConfirmDialog from "../../utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FMaterial from "../../../models/f-material";
import { format, parseISO } from 'date-fns'
import FMaterialGeneral from "./FMaterialGeneral"
import FMaterialUomAndPrice from "./FMaterialUomAndPrice";
import FMaterialInventory from "./FMaterialInventory";
import FMaterialAccounting from "./FMaterialAccounting";
import FMaterialOlStoreDesc from "./FMaterialOlStoreDesc";
import FMaterialOlStoreGallery from "./FMaterialOlStoreGallery";
import FMaterialMapService from "@/services/apiservices/f-material-map-service";

export default {
  components: {
    FMaterialOlStoreGallery,
    FMaterialOlStoreDesc,
    FMaterialAccounting,
    FMaterialInventory,
    FMaterialUomAndPrice,
    FMaterialGeneral,
    CloseConfirmDialog
  },
  props:{
    formMode: String,
    itemsFDivision: [],

    itemsMaterialType:[],
    itemsFMaterialGroup3:[],
    itemsFMaterialSalesBrand:[],
    itemsFVendor:[],
    itemsFWarehouse:[],
    itemsFTax:[]

  },
  data() {
    return {
      visibleSecurity: false,
      title: 'MATERIAL PRODUCT',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,


      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FMaterial(0, '', ''),
      selectFDivision: '',


      itemsFile:[],


      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

  },

  watch: {
  },

  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item){
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FMaterial(),
        this.itemModified =  new FMaterial(),
        this.selectedIndex = -1
      }

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(andCloseDialog){
      // console.log(andCloseDialog)
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }

      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();

      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {

          // console.log(`cek sebelum update >> ${this.itemModified.id}`)
          FMaterialService.updateFMaterialAndFix(this.itemModified).then(
              () => {
                // console.log(response.data)
                if (andCloseDialog){
                  this.$emit('eventFromFormDialogEdit', this.itemModified)
                }
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {

          if (this.itemModified.fdivisionBean >0 && this.itemModified.pcode !==undefined &&
              this.itemModified.pname !==undefined){
            /**
             * UNTUK BARU HARUS POSISI NON-AKTIF
             */
            // this.itemModified.statusActive = false

            FMaterialService.createFMaterial(this.itemModified).then(
                response =>{
                  if (andCloseDialog){
                    this.$emit('eventFromFormDialogNew', response.data)
                  }
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }
        }//endif
      }//endif validate

    },

    saveCreateOnly(){
      FMaterialService.createFMaterial(this.itemModified).then(
          response =>{
              /**
               * dipaksa Save dan Update Dahulu
               */
              // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''
      const id = item.id
      FMaterialService.getFMaterialById(id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data

            this.$store.dispatch("updateItemModified", response.data)
            this.retrieveFiles()

          },
          error =>{
            console.log(error)
          }
      )

    },
    retrieveFiles(){
      FMaterialFileService.getAllFMaterialFileByParent(this.itemModified.id).then(
          response=>{
            // console.log(`all item files: ${JSON.stringify(response.data)}`)
            this.itemsFile = response.data
          },
          error=>{
            console.log(error.response)
          }
      ),
      FMaterialMapService.getAllFMaterialMapByParent(this.itemModified.id).then(
          response => {
            this.$store.dispatch("updateListFMaterialMap", response.data)
          },
          error =>{
            this.$store.dispatch("updateListFMaterialMap", [])
            console.log(error)
          }
      )

    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  }

}
</script>

<style scoped>
</style>