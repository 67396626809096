export default class FMaterialFile {
  constructor(
      id,
      fmaterialBean,
      fileType,
      parentType,
      fileName,
      title,
      description,
      uploadFrom,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.fmaterialBean = fmaterialBean;
    this.fileType = fileType;
    this.parentType = parentType;
    this.fileName = fileName;
    this.title = title;
    this.description = description;
    this.uploadFrom = uploadFrom;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
