export default class FMaterialFilter {
  constructor(
      pageNo= 0,
      pageSize = 10,
      sortBy="id",
      order="DESC",
      search="",

      fdivisionIds =[],
      fvendorIds=[],
      fmaterialGroup3Ids=[],
      statusActive= 0,

  ) {
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.order = order;
    this.search = search;

    this.fdivisionIds = fdivisionIds;
    this.fvendorIds = fvendorIds;
    this.fmaterialGroup3Ids = fmaterialGroup3Ids;
    this.statusActive = statusActive;
  }

}
